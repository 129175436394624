import React, { useEffect, useState, useRef } from "react";
import { getDb } from "../firebase";
import { ref, get, child, onValue, onDisconnect } from "firebase/database";
import { GlobalSettings, PublicUserPresence } from "../../../Widget/src/interfaces/UserPresence";
import { getAuth, signInAnonymously } from "firebase/auth";

import QueueView from "./QueueView";
import MaintenanceView from "./MaintenanceView";
import Footer from "./Footer";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

export const usePrevious = (value: any) => {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};

interface EntryQueueProps {}

interface RequestParams {
	uid: string;
	clientKey: string;
	path: string;
	ua: string;
	bypassCode?: string;
	handoffId?: string;
	forceQueue?: boolean;
	v: string;
	wid: string;
}

const BF_QUEUE_ID = "bf-queue-id";

const EntryQueue: React.FC<EntryQueueProps> = ({}) => {
	const [accessAllowed, setAccessAllowed] = useState(false);
	//const [authReady, setAuthReady] = useState(false);
	const [showMessages, setShowMessages] = useState(false);
	const [loading, setLoading] = useState(true);
	const [showLink, setShowLink] = useState(false);
	const [settings, setSettings] = useState<GlobalSettings>();
	const [me, setMe] = useState<PublicUserPresence | null>();
	const [pageError, setPageError] = useState<{ status: number; error: string; message?: string }>();
	const [playAudio, setPlayAudio] = React.useState(false);
	const { pathname } = useLocation();

	const [uid, setUid] = useState<string>();

	useEffect(() => {
		const localUUID = localStorage.getItem(BF_QUEUE_ID);
		if (localUUID) {
			setUid(localUUID);
		} else {
			const newUid = uuidv4();
			localStorage.setItem(BF_QUEUE_ID, newUid);
			setUid(newUid);
		}
	}, []);

	const isStaging = window.location.hostname === "queue.brushfirestaging.com";

	const db = getDb(isStaging);

	//const auth = getAuth();
	const clientKey = window.location.pathname.split("/")[1];
	const urlKey = window.location.pathname.split("/")[2];
	const eventNumber = [6, 7].includes(window.location.pathname.split("/")[3]?.length)
		? window.location.pathname.split("/")[3]
		: undefined;

	const createPresence = (payload: RequestParams) => {
		const widgetSourceClient = axios.create({
			baseURL: isStaging
				? `https://apistaging-st3i2yb5oq-uc.a.run.app`
				: `https://apigen2-st3i2yb5oq-uc.a.run.app`,
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});
		widgetSourceClient.post("/attendees", payload).then(({ data }) => {
			if (data.success === true) {
				console.log("Letting you through!");
			} else {
				console.error("Something went wrong while creating your session");
				setPageError({
					status: 500,
					error: "Unable to connect to Brushfire",
				});
			}
		});
	};

	const previousAllow = usePrevious(me?.allow);

	const params = new URLSearchParams(document.location.search);
	const bypass = params.get("bypass");

	useEffect(() => {
		if (uid) {
			// auth.onAuthStateChanged((user) => {
			// 	if (user) {
			// 		setAuthReady(true);
			// 	}
			// });
			//Sign everyone in with an anonymous account
			//This lets us write nice security rules for RTdb and Firestore
			//signInAnonymously(auth)
			//.then((userCredential) => {
			//Get the global settings so we know if we need to
			//queue the users or show the maintenance page
			const userRef = ref(db, `/attendees/${clientKey}/${uid}`);
			const settingsRef = ref(db, "/settings");
			get(settingsRef)
				.then((snapshot) => {
					if (snapshot.exists()) {
						const settings = snapshot.val() as GlobalSettings;
						if (settings) {
							//This is a ref unique for each user so we can reliablly tell if they are connected
							//Even if the connection times out, the snapshot listener attempts to reconnect,
							//so people are only disconnected for avery short time
							const connectedRef = ref(db, ".info/connected");
							onValue(connectedRef, (snapshot) => {
								if (snapshot.val() === true) {
									onDisconnect(child(userRef, "connected"))
										.set(false)
										.then(() => {
											// The promise returned from .onDisconnect().set() will
											// resolve as soon as the server acknowledges the onDisconnect()
											// request, NOT once we've actually disconnected:
											// https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

											// We can now safely set ourselves as 'online' knowing that the
											// server will mark us as offline once we lose connection.

											createPresence({
												uid: uid,
												clientKey,
												path: window.location.pathname,
												ua: window.navigator.userAgent,
												bypassCode: bypass ?? undefined,
												handoffId: undefined,
												forceQueue: true,
												v: document.getElementById("brushfire")?.getAttribute("src") ?? "",
												wid: "queue",
											});
										})
										.catch((reason) => {
											setPageError({
												status: 500,
												error: "onDisconnect was unable to be established",
												message: reason.toString(),
											});
										});
								}
							});
						} else {
							//The settings are gone? This should never happen, so throw an error
							setPageError({
								status: 500,
								error: "Unable to connect to the entry queue settings provider",
							});
						}
					} else {
						//The settings are gone? This should never happen, so throw an error
						setPageError({
							status: 500,
							error: "Unable to connect to the entry queue settings provider",
						});
					}
				})
				.catch((error) => {
					setPageError({
						status: 500,
						error: error.stack,
						message: error.message,
					});
				});
			//})
			// .catch((error) => {
			// 	setPageError({
			// 		status: error.code,
			// 		error: error.stack,
			// 		message: error.message,
			// 	});
			// });
		}
	}, [uid]);

	useEffect(() => {
		const settingsRef = ref(db, "/settings");
		return onValue(settingsRef, (snapshot) => {
			setSettings(snapshot.val() as GlobalSettings);
		});
	}, []);

	useEffect(() => {
		if (playAudio) {
			const audioInterval = setInterval(() => {
				const audio = new Audio(`https://brushfirecontent.blob.core.windows.net/0000/Media/queue-chime.mp3`);
				audio.volume = 0;
				audio.play();
			}, 3000);
			return () => {
				clearInterval(audioInterval);
			};
		}
	}, [playAudio]);

	useEffect(() => {
		if (uid && !accessAllowed) {
			//Create subscription to allow property to determine if the user may go through
			const userRef = ref(db, `/public-attendees/${clientKey}/${uid}`);
			return onValue(userRef, (snapshot) => {
				const user = snapshot.val() as PublicUserPresence | null;
				if (!user?.allow) {
					const audio = new Audio(
						`https://brushfirecontent.blob.core.windows.net/0000/Media/queue-chime.mp3`
					);
					audio.volume = 0;
					audio.play();
				}

				if (user?.allow !== accessAllowed && user?.allow !== undefined) {
					setAccessAllowed(true);
				}
				if (user?.allow === false) {
					setShowMessages(true);
				}
				if (user?.connected !== true && !!uid) {
					//If we get disconnected, while still connected, reconnect

					createPresence({
						uid: uid ?? "",
						clientKey,
						path: window.location.pathname,
						ua: window.navigator.userAgent,
						forceQueue: true,
						v: document.getElementById("brushfire")?.getAttribute("src") ?? "",
						wid: "queue",
					});
				}
				setMe(user);
			});
		}
	}, [accessAllowed, uid]);

	useEffect(() => {
		if (previousAllow === false && me?.allow === true) {
			if (playAudio) {
				const audio = new Audio(`https://brushfirecontent.blob.core.windows.net/0000/Media/queue-chime.mp3`);
				audio.play();
			}
		}
	}, [me?.allow]);

	const link = `${window.location.href
		.replace("queue.", window.location.href.includes(".local") ? "widget." : "")
		.replace(":4080", ":4000")}${window.location.href.includes("?") ? "&" : "?"}u=${uid}`;

	useEffect(() => {
		if (accessAllowed === true && uid) {
			window.location.href = link;
			setTimeout(() => {
				setShowLink(true);
			}, 3000);
		}
	}, [accessAllowed]);

	if (settings?.maintenance.on) {
		return (
			<div className={`page-body ta-left m-top-medium`}>
				<MaintenanceView settings={settings} eventNumber={eventNumber} />
			</div>
		);
	}
	return (
		<>
			<div className="queue-view" style={{ textAlign: "center" }}>
				{!!pageError && (
					<div className="alert alert-danger">
						<h4>{pageError.error}</h4>
					</div>
				)}
				{settings?.clients[clientKey]?.message && (
					<div className={`page-body ta-left m-top-medium`}>
						<div
							className={
								settings?.clients[clientKey].variant === "well"
									? "well"
									: `alert alert-${settings?.clients[clientKey].variant ?? "primary"}`
							}
						>
							<div
								className={`${
									settings?.clients[clientKey].variant === "well" ? "well-content" : "alert-body"
								}`}
							>
								{settings?.clients[clientKey].message}
							</div>
						</div>
					</div>
				)}
				{!!settings && showMessages && (
					<div className={`page-body ta-left`}>
						{settings.maintenance.on ? (
							<>
								<h1>{settings.maintenance.heading}</h1>
								<p>{settings.maintenance.body}</p>
							</>
						) : (
							<>
								<QueueView
									settings={settings}
									me={me}
									eventNumber={eventNumber}
									urlKey={urlKey}
									clientKey={clientKey}
									playAudio={playAudio}
									setPlayAudio={setPlayAudio}
									accessAllowed={accessAllowed}
									showLink={showLink}
									link={link}
								/>
							</>
						)}
					</div>
				)}
			</div>
			<Footer />
		</>
	);
};

export default EntryQueue;
