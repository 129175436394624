import React, { useState, useEffect } from "react";
import fb, { getDb } from "../firebase";
import { getDatabase, ref, onValue, set, get } from "firebase/database";
import { UserPresence, LiveStats, GlobalSettings, LiveClientLog } from "../../../Widget/src/interfaces/UserPresence";
import moment from "moment";
import LiveUserDot from "./LiveUserDot";
import { Bar, BarChart, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
//@ts-ignore
import { DefaultTooltipContent } from "recharts/lib/component/DefaultTooltipContent";

import { getAuth } from "firebase/auth";
// import { Button } from "@brushfire/ui";
import FlipMove from "react-flip-move";
import StatusButton, { Status } from "./StatusButton";
import { Formik, Field, Form } from "formik";
import { Button, Modal } from "react-bootstrap";
import UAParser from "ua-parser-js";
import Helmet from "react-helmet";
import { useHistoricalData } from "./HistoricalProvider";
import { Loader } from "../Components/Loader";
import { Routes } from "../Constants";
import { useLocation } from "react-router-dom";
import ViewDropdown from "./ViewDropdown";

interface AllUsers {
	[clientKey: string]: { [uid: string]: UserPresence };
}

interface LiveProps {
	settings?: GlobalSettings;
	liveStats?: LiveStats;
	setShowModal: (show: boolean) => void;
	path: string;
}

interface ClientRecord extends LiveClientLog {
	clientKey: string;
}

const originDate = "2011-06-11T17:49:00";

const owners = [
	"jared@brushfire.com",
	"jorin@brushfire.com",
	"david@brushfire.com",
	"pete@brushfire.com",
	"clayton@brushfire.com",
	"minjae@brushfire.com",
	"matthewlamperski@brushfire.com",
];

const Live: React.FC<LiveProps> = ({ settings, liveStats, setShowModal }) => {
	const [users, setUsers] = useState<AllUsers>();
	const [sync, setSync] = useState(false);
	const [showStagnant, setShowStagnant] = useState(false);
	const [modalClientKey, setModalClientKey] = useState<string>();
	const [useNewAttendees, setUseNewAttendees] = useState(true);
	const { historicalData, isLive, loadingData, onGoBackward, onGoForward } = useHistoricalData();
	const { pathname } = useLocation();

	const loadData = historicalData.length > 0 ? historicalData : liveStats?.load ?? [];

	const db = getDb(pathname === Routes.dashboardStaging);
	const now = moment.utc();
	const auth = getAuth();

	useEffect(() => {
		const presenceRef = ref(db, "/presence");
		const attendeesRef = ref(db, "/attendees");
		if (sync) {
			get(presenceRef).then((pres) => {
				get(attendeesRef).then((atts) => {
					const uids: { [uid: string]: string } = {};
					const pUsers = pres.val() as AllUsers;
					const aUsers = atts.val() as AllUsers;
					console.log("start");
					Object.keys(pUsers).forEach((clientKey) => {
						Object.keys(pUsers[clientKey]).forEach((uid) => {
							if (!Object.keys(uids).find((u) => uid)) {
								uids[uid] = clientKey;
							}
							// const user = pUsers[clientKey][uid];
							// if (now.diff(moment.utc(user.uts ?? user.ts), "seconds") < 120 && user.connected && !!user.path) {
							// 	count++;
							// }
						});
					});
					Object.keys(aUsers).forEach((clientKey) => {
						Object.keys(aUsers[clientKey]).forEach((uid) => {
							if (Object.keys(uids).find((u) => u === uid)) {
								if (pUsers[uids[uid]]?.[uid]?.ts) {
									console.log(
										"trouble",
										uid,
										moment(aUsers[clientKey][uid].ts).diff(
											moment(pUsers[uids[uid]][uid].ts),
											"minutes"
										),
										clientKey,
										uids[uid]
									);
								} else {
									console.log("different client", uids[uid]);
								}
							}
							// const user = pUsers[clientKey][uid];
							// if (now.diff(moment.utc(user.uts ?? user.ts), "seconds") < 120 && user.connected && !!user.path) {
							// 	count++;
							// }
						});
					});
					console.log("done");
				});
			});

			return onValue(useNewAttendees ? attendeesRef : presenceRef, (snapshot) => {
				if (snapshot.exists()) {
					const allUsers = snapshot.val() as AllUsers;
					if (allUsers) {
						setUsers(allUsers);
						const properties: { [keys: number]: number } = { 100: 0 };
						const uni: { [key: string]: number } = {};
						Object.keys(allUsers).forEach((clientKey) => {
							const c = allUsers[clientKey];
							Object.keys(c).forEach((k) => {
								const u = c[k];
								if (u.uts) {
									const stagnant = now.diff(moment.utc(u.uts), "seconds") > 120;
									if (!stagnant && u.connected === true && u.path !== "test") {
										const keys = Object.keys(u).length;
										if (!properties[keys]) {
											properties[keys] = 1;
										} else {
											properties[keys] += 1;
										}
										uni[k] = 0;
									}
								} else {
									properties[100] += 1;
								}
							});
						});
					} else {
						//The settings are gone? This should never happen, so throw an error
					}
				} else {
					//The settings are gone? This should never happen, so throw an error
				}
			});
		}
	}, [sync, useNewAttendees]);

	const simulateUsers = () => {
		for (let i = 0; i < 50; i++) {
			const userRef = ref(db, `/presence/brushfiretech/100${i}`);

			set(userRef, {
				allow: false, //REMOVE THIS!!!!!!!
				connected: true,
				online: true,
				i: 0,
				carts: {},
				ts: moment.utc().toISOString(),
				ats: "",
				dts: "",
				uts: moment.utc().toISOString(),
				path: "test",
				c: "brushfiretech",
			});
		}
	};

	// const analyzeUsers = () => {
	// 	fb;
	// };
	moment.locale("en");
	const activeClients = Object.values(liveStats?.clients ?? {}).filter((c) => c.u > 0 || (c.q ?? 0) > 0).length ?? 0;
	const activeAdminClients =
		Object.keys(liveStats?.clients ?? {}).filter((clientKey) => {
			return clientKey.startsWith("webapp_") && (liveStats?.clients[clientKey].a ?? 0 > 0);
		}).length ?? 0;
	const clientsWithQueuedUsers = liveStats?.clients
		? Object.keys(liveStats?.clients ?? {}).filter((c) => (liveStats.clients[c].q ?? 0) > 0) ?? []
		: [];

	const clientBreakdownData: any = {};
	clientBreakdownData.name = "Clients";
	clientsWithQueuedUsers.forEach((clientKey) => {
		clientBreakdownData[clientKey] = liveStats?.clients[clientKey].q ?? 0;
	});

	const totalLive = liveStats?.clients
		? Object.keys(liveStats.clients)
				.map((clientKey) => liveStats?.clients[clientKey].u ?? 0)
				.reduce((a, b) => {
					return a + b;
				})
		: 0;

	const totalQueued =
		clientsWithQueuedUsers.length > 0
			? clientsWithQueuedUsers
					.map((clientKey) => liveStats?.clients[clientKey].q ?? 0)
					.reduce((a, b) => {
						return a + b;
					})
			: 0;

	const queuedClients = Object.keys(liveStats?.clients ?? {}).filter((clientKey) =>
		settings?.clients[clientKey]
			? settings?.clients[clientKey]?.on ||
			  now.isBetween(
					moment.utc(settings?.clients[clientKey]?.startsAt ?? originDate),
					moment.utc(
						settings?.clients[clientKey].endsAt ??
							(settings?.clients[clientKey].startsAt ? "2051-06-11T17:49:00" : originDate)
					)
			  )
			: false || (liveStats?.clients?.[clientKey]?.q ?? 0) > 0
	);

	const onClients = Object.keys(settings?.clients ?? {}).filter((clientKey) => settings?.clients[clientKey].on);

	const forcedClients = Object.keys(settings?.clients ?? {}).filter((clientKey) =>
		now.isBetween(
			moment.utc(settings?.clients[clientKey].startsAt ?? originDate),
			moment.utc(
				settings?.clients[clientKey].endsAt ??
					(settings?.clients[clientKey].startsAt ? "2051-06-11T17:49:00" : originDate)
			)
		)
	);

	const scheduledClients = Object.keys(settings?.clients ?? {}).filter((clientKey) =>
		now.isBefore(moment.utc(settings?.clients[clientKey].startsAt ?? originDate))
	);

	const orderedClients: ClientRecord[] = Object.entries(liveStats?.clients ?? {})
		.reduce<ClientRecord[]>((accumulator, [clientKey, clientData]) => {
			const key = clientKey.replace(/^webapp_/, "");
			const existingIdx = accumulator.findIndex(({ clientKey }) => clientKey === key);

			if (existingIdx !== -1) {
				accumulator[existingIdx].u += clientData.u;
				accumulator[existingIdx].q = (accumulator[existingIdx].q ?? 0) + (clientData.q ?? 0);
				accumulator[existingIdx].s = (accumulator[existingIdx].s ?? 0) + (clientData.s ?? 0);
				accumulator[existingIdx].a = (accumulator[existingIdx].a ?? 0) + (clientData.a ?? 0);
			} else {
				accumulator.push({ ...clientData, clientKey: key });
			}

			return accumulator;
		}, [])
		.sort((a, b) => {
			const aTotal = a.u + (a.q ?? 0) + (a.a ?? 0);
			const bTotal = b.u + (b.q ?? 0) + (b.a ?? 0);
			return aTotal - bTotal;
		});

	queuedClients.forEach((clientKey) => {
		if (!orderedClients.find((o) => o.clientKey === clientKey)) {
			orderedClients.push({
				clientKey,
				u: 0,
			});
		}
	});

	const turnedOnClients = Object.keys(settings?.clients ?? {}).filter(
		(clientKey) => settings?.clients[clientKey].startsAt && !settings?.clients[clientKey].endsAt
	);

	if (sync && users) {
		let count = 0;
		Object.keys(users).forEach((clientKey) => {
			Object.keys(users[clientKey]).forEach((uid) => {
				const user = users[clientKey][uid];
				const time = now.diff(moment.utc(user.uts || user.ts), "seconds");
				if (
					(!!user.uts || !!user.ts) &&
					time < 120 &&
					user.connected &&
					!!user.path &&
					!clientKey.startsWith("webapp_")
				) {
					count++;
				}
			});
		});
		console.log("Count", count);
	}

	const renderClient = (client: ClientRecord) => {
		if (
			client.clientKey === "-" ||
			(client?.u === 0 &&
				(client?.q ?? 0) === 0 &&
				![...onClients, ...forcedClients, ...scheduledClients].includes(client.clientKey))
		) {
			return null;
		}
		return (
			<div
				className={`well lg ta-center d-block ${
					onClients.includes(client.clientKey) || (client?.q ?? 0) > 0 ? "queue-on" : ""
				} ${
					forcedClients.includes(client.clientKey) || turnedOnClients.includes(client.clientKey)
						? "queue-forced"
						: ""
				} ${settings?.paused ? "paused" : ""} ${
					owners.includes(auth?.currentUser?.email ?? "") ? "cursor-pointer" : ""
				}`}
				key={client.clientKey}
				onClick={() => {
					if (owners.includes(auth?.currentUser?.email ?? "")) {
						setModalClientKey(client.clientKey);
					}
				}}
			>
				<div className={`well-content ta-left`}>
					<div className="row">
						<div className={`col-md-9`}>
							<h4 className="ta-uppercase">
								<a
									href={`https://brushfire.com/${client.clientKey}`}
									onClick={(e) => {
										e.stopPropagation();
									}}
									target="_blank"
									rel="noreferrer"
								>
									{client.clientKey}
								</a>
							</h4>
						</div>
						<div className={`col-md-3 ta-right`}>
							<h5>{`Attendees: ${client.u}`}</h5>
							<h5>{`In Queue: ${client?.q ?? 0}`}</h5>
							<h5>{`Admins: ${client?.a ?? 0}`}</h5>
							{/* <StatusButton
								text={`${
									turnedOnClients.includes(client.clientKey)
										? "On"
										: scheduledClients.includes(client.clientKey) ||
										  now.isBefore(
												moment.utc(settings?.clients[client.clientKey]?.endsAt ?? originDate)
										  )
										? "Scheduled"
										: "Auto"
								}${client?.q ? ` | ${client?.q}` : ""}`}
								status={
									onClients.includes(client.clientKey) || forcedClients.includes(client.clientKey)
										? Status.on
										: scheduledClients.includes(client.clientKey)
										? Status.waiting
										: Status.off
								}
								onClick={() => {
									setModalClientKey(client.clientKey);
								}}
							/> */}
						</div>
					</div>
				</div>
			</div>
		);
	};

	const iconSuffix =
		settings?.maintenance.on || settings?.paused
			? "Paused"
			: settings?.queueState === 1
			? `Auto`
			: settings?.queueState === 2
			? "On"
			: "Off";

	return (
		<>
			<Helmet>
				<title>Brushfire Queue - {iconSuffix}</title>
				<link rel="shortcut icon" href={`/favicon-queue-${iconSuffix.toLowerCase()}.ico`} />
			</Helmet>
			<div className="row">
				<div className={`col-md-3 dashboard-blocks`}>
					<div className="well lg ta-center d-block w-100">
						<div className={`well-content`}>
							<h1 className="attendee-visitors-color">{liveStats?.u ?? "-"}</h1>
							<h3 className="tt-uppercase">{`Attendee${liveStats?.u === 1 ? "" : "s"} On Site`}</h3>
							<h5 className="tt-uppercase">
								Across {activeClients} Client{activeClients !== 1 ? "s" : ""}
							</h5>
							<h4 className="tt-uppercase queued-attendees-color">{liveStats?.q ?? "-"} In Queue</h4>
							<h5 className="tt-uppercase">
								Across {clientsWithQueuedUsers.length} Client
								{clientsWithQueuedUsers.length !== 1 ? "s" : ""}
							</h5>
						</div>
					</div>
				</div>
				<div className={`col-md-3 dashboard-blocks`}>
					<div className="well lg ta-center d-block w-100">
						<div className={`well-content`}>
							<h1 className="admin-visitors-color">{liveStats?.a ?? "-"}</h1>
							<h3 className="tt-uppercase">{`Admin${liveStats?.a === 1 ? "" : "s"} On Site`}</h3>
							<h5 className="tt-uppercase">
								Across {activeAdminClients} Client{activeAdminClients !== 1 ? "s" : ""}
							</h5>
							{/* insert queued admins info */}
							<h4 className="tt-uppercase queued-admins-color">0 In Queue</h4>
							<h5 className="tt-uppercase">Across 0 Clients</h5>
						</div>
					</div>
				</div>
				<div className={`col-md-3 dashboard-blocks`}>
					<div className="well lg ta-center d-block w-100">
						<div className={`well-content`}>
							<h1 className="attendee-limit-color">{settings?.internetLimit ?? "-"}</h1>
							<h3 className="tt-uppercase">Attendee Limit</h3>
							<h5 className="tt-uppercase">{(settings?.clientQueuePercent ?? 0) * 100}% Per Client</h5>
						</div>
					</div>
				</div>
				<div className={`col-md-3 dashboard-blocks`}>
					<div className="well lg ta-center d-block w-100">
						<div className={`well-content`}>
							<h1>{liveStats?.load[99]?.d ? `${Math.ceil(liveStats.load[99]?.d)}%` : "-"}</h1>
							<h3 className="tt-uppercase">Database</h3>
							<h5 className="tt-uppercase">{liveStats?.load[99]?.c} Cores</h5>
						</div>
					</div>
				</div>
			</div>

			<div className="well lg ta-center d-block">
				<div className={`well-content`}>
					<ViewDropdown />
					<div className="queue-dashboard-section live-graph">
						<ResponsiveContainer width="100%" height={400}>
							<LineChart
								data={loadData.map((load, index) => {
									return {
										name: index,
										value: load.o,
										l: load.l,
										q: load.q,
										o: load.o,
										u: load.u,
										d: load.d,
										c: load.c,
										a: load.a ?? 0,
										ts: moment(load.ts).format("MMMM Do, YYYY h:mmA"),
										p: load.l * (load?.p ?? settings?.proactiveQueuePercent ?? 0),
										qa: 0,
									};
								})}
								margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
							>
								<Line
									type="monotone"
									name="Attendee Visitors"
									dataKey="o"
									stroke="#11a9f0"
									activeDot={{ r: 8 }}
									dot={false}
									strokeWidth={3}
									isAnimationActive={false}
								/>
								<Line
									type="monotone"
									name="Attendees in queue"
									dataKey="q"
									stroke="#0AC273"
									strokeWidth={3}
									activeDot={{ r: 4 }}
									dot={false}
									isAnimationActive={false}
								/>
								<Line
									type="monotone"
									name="Admin Visitors"
									dataKey="a"
									stroke="#0876e8"
									activeDot={{ r: 4 }}
									dot={false}
									strokeWidth={1}
									isAnimationActive={false}
								/>
								<Line
									type="monotone"
									name="Admins in queue"
									dataKey="qa"
									stroke="#00371f"
									strokeWidth={3}
									activeDot={{ r: 4 }}
									dot={false}
									isAnimationActive={false}
								/>
								<Line
									type="monotone"
									name="Attendee Limit"
									dataKey="l"
									stroke="#ffa800"
									activeDot={{ r: 4 }}
									dot={false}
									strokeWidth={1}
									isAnimationActive={false}
								/>
								<Line
									type="monotone"
									name="Proactive Limit"
									dataKey="p"
									stroke="#ffa800"
									activeDot={{ r: 4 }}
									dot={false}
									strokeWidth={1}
									strokeDasharray="4"
									isAnimationActive={false}
								/>
								<Line
									type="monotone"
									name="CPU Percent"
									dataKey="d"
									stroke="#24272b"
									strokeDasharray={"0 900"}
									dot={false}
									activeDot={false}
									isAnimationActive={false}
								/>
								<Line
									type="monotone"
									name="Cores"
									dataKey="c"
									stroke="#24272b"
									strokeDasharray={"0 900"}
									dot={false}
									activeDot={false}
									isAnimationActive={false}
								/>
								<Line
									type="monotone"
									name="Time"
									dataKey={"ts"}
									stroke="#24272b"
									strokeDasharray={"0 900"}
									dot={false}
									activeDot={false}
									contentStyleType={"string"}
									isAnimationActive={false}
								/>
								<Tooltip
									formatter={(value, name) => {
										if (name === "Time") {
											return moment(value, "MMMM Do, YYYY h:mmA").format("L h:mmA");
										} else {
											return value;
										}
									}}
								/>
							</LineChart>
						</ResponsiveContainer>
						<ResponsiveContainer width="100%" height={400}>
							<BarChart
								width={500}
								height={400}
								margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
								data={loadData.map((load, index) => {
									return {
										name: index,
										value: load.o,
										c: (100 - Math.round(load.d)) * load.c,
										d: Math.round(load.d) * load.c,
									};
								})}
							>
								<Tooltip />
								<Bar dataKey="d" stackId="a" fill="#b0b8bf" isAnimationActive={false} />
								<Bar dataKey="c" stackId="a" fill="#e5e6e780" isAnimationActive={false} />
							</BarChart>
						</ResponsiveContainer>
						{!isLive && !loadingData && (
							<>
								<div onClick={onGoBackward} className="arrow arrow-left">
									<i className="fas fa-caret-left" />
								</div>
								<div onClick={onGoForward} className="arrow arrow-right">
									<i className="fas fa-caret-right" />
								</div>
							</>
						)}
						{
							<div className={`data-loading ${!isLive && loadingData ? "" : "d-none"}`}>
								<Loader />
							</div>
						}
					</div>
					<div className="row" style={{ opacity: loadingData ? 0 : 1 }}>
						<div className="col col-xs-6 ta-left" title={liveStats?.load?.at(0)?.ts}>
							<small>
								{isLive
									? moment.utc(loadData?.at(0)?.ts).fromNow()
									: moment(loadData?.at(0)?.ts)
											.locale("en")
											.format("MMMM Do, YYYY h:mmA")}
							</small>
						</div>
						<div className="col col-xs-6 ta-right" title={liveStats?.load?.at(-1)?.ts}>
							<small>
								{isLive
									? moment.utc(loadData?.at(-1)?.ts).fromNow()
									: moment(loadData?.at(-1)?.ts)
											.locale("en")
											.format("MMMM Do, YYYY h:mmA")}
							</small>
						</div>
					</div>
					{liveStats?.clients && isLive && (
						<>
							<div className="m-top-large ta-left">
								<h6>Users Online</h6>
								<div className="horizontal-bars">
									{Object.keys(liveStats.clients).map((clientKey, index) => {
										if (liveStats.clients[clientKey].u === 0) {
											return null;
										}
										return (
											<div
												className="horizontal-bar"
												style={{
													backgroundColor: ["#004bed", "#0033a1", "#0876e8", "#11a9f0"][
														index % 4
													],
													width: `${(liveStats.clients[clientKey].u / totalLive) * 100}%`,
												}}
												key={clientKey}
												title={`${clientKey}: ${liveStats.clients[clientKey].u} User${
													liveStats.clients[clientKey].u === 1 ? "" : "s"
												} Online`}
											/>
										);
									})}
								</div>
							</div>
							<div className="m-top-large ta-left">
								<h6>Users In Queue</h6>
								<div className="horizontal-bars">
									{clientsWithQueuedUsers.map((clientKey, index) => {
										return (
											<div
												className="horizontal-bar"
												style={{
													backgroundColor: ["#004bed", "#0033a1", "#0876e8", "#11a9f0"][
														index % 4
													],
													width: `${(clientBreakdownData[clientKey] / totalQueued) * 100}%`,
												}}
												key={clientKey}
												title={`${clientKey}: ${clientBreakdownData[clientKey]} User${
													clientBreakdownData[clientKey] === 1 ? "" : "s"
												} In Queue`}
											/>
										);
									})}
								</div>
							</div>
						</>
					)}
				</div>
			</div>
			<div className="m-top-medium"></div>
			{(auth.currentUser?.email === "jared@brushfire.com" ||
				auth.currentUser?.email === "jorin@brushfire.com") && (
				<Formik
					initialValues={{
						clientKey: "",
					}}
					onSubmit={async (values, actions) => {
						setModalClientKey(values.clientKey);
						actions.resetForm({ values: { clientKey: "" } });
					}}
				>
					{() => (
						<Form>
							<div className="row">
								<div className={`col-md-8`}>
									<div className="field m-0">
										<Field name="clientKey" placeholder="Client Key" className="d-block w-100" />
									</div>
								</div>
								<div className={`col-md-4`}>
									<Button variant="success" type="submit" className="btn-sm d-block w-100">
										+ Add A Client
									</Button>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			)}
			{(auth.currentUser?.email === "jared@brushfire.com" ||
				auth.currentUser?.email === "jorin@brushfire.com") && (
				<>
					<button
						// purpose={sync ? "danger" : "primary"}
						className="m-top btn btn-primary"
						onClick={() => {
							setSync(!sync);
						}}
					>
						{sync ? "Stop User Dots" : "Start User Dots"}
					</button>
					<button
						// purpose={sync ? "danger" : "primary"}
						className="m-top btn btn-info"
						onClick={() => {
							setUseNewAttendees(!useNewAttendees);
						}}
					>
						{useNewAttendees ? "View Old Data" : "View New Data"}
					</button>
					<button
						className="m-top btn btn-secondary"
						onClick={() => {
							setShowStagnant(!showStagnant);
						}}
					>
						{showStagnant ? "Hide Stagnant" : "Show Stagnant"}
					</button>
					<div className="queue-dashboard-section live">
						{!!users && (
							<div className="user-chart">
								<div className="vertical-line 0"></div>
								<div className="vertical-line 1"></div>
								<div className="vertical-line 2"></div>
								<div className="vertical-line 3"></div>
								<div className="vertical-line 4"></div>
								<div className="vertical-line 5"></div>
								<div className="vertical-line 6"></div>
								{Object.keys(users).map((clientKey, index, clientKeys) => {
									return Object.keys(users[clientKey]).map((uid, userIndex, userKeys) => {
										return (
											<LiveUserDot
												key={`${clientKey}-${uid}`}
												clientKey={clientKey}
												uid={uid}
												user={users[clientKey][uid]}
												now={now}
												y={index / clientKeys.length}
												x={userIndex / userKeys.length}
												showStagnant={showStagnant}
											/>
										);
									});
								})}
							</div>
						)}
					</div>
				</>
			)}
			{isLive && (
				<div className="row">
					<div className="col-md-6">
						{/* @ts-ignore */}
						<FlipMove>
							{orderedClients
								.filter(
									(c) =>
										queuedClients.includes(c.clientKey) ||
										scheduledClients.includes(c.clientKey) ||
										(c.q ?? 0) > 0
								)
								.map(renderClient)}
						</FlipMove>
					</div>
					<div className="col-md-6">
						{/* @ts-ignore */}
						<FlipMove>
							{orderedClients
								.filter(
									(c) =>
										!queuedClients.includes(c.clientKey) &&
										!scheduledClients.includes(c.clientKey) &&
										(c.q ?? 0) === 0
								)
								.map(renderClient)}
						</FlipMove>
					</div>
				</div>
			)}

			<Modal
				show={!!modalClientKey}
				onHide={() => {
					setModalClientKey(undefined);
				}}
				centered
			>
				{!!modalClientKey && (
					<Formik
						initialValues={{
							queue: turnedOnClients.includes(modalClientKey)
								? "On"
								: scheduledClients.includes(modalClientKey) ||
								  now.isBefore(moment.utc(settings?.clients[modalClientKey]?.endsAt ?? originDate))
								? "Scheduled"
								: "Auto",
							startsAt:
								scheduledClients.includes(modalClientKey) ||
								now.isBefore(moment.utc(settings?.clients[modalClientKey]?.endsAt ?? originDate))
									? settings?.clients[modalClientKey]?.startsAt
									: moment
											.utc()
											.add(7, "days")
											.format(moment.HTML5_FMT.DATETIME_LOCAL),
							endsAt:
								scheduledClients.includes(modalClientKey) ||
								now.isBefore(moment.utc(settings?.clients[modalClientKey]?.endsAt ?? originDate))
									? settings?.clients[modalClientKey]?.endsAt
									: moment
											.utc()
											.add(7, "days")
											.add(1, "hour")
											.format(moment.HTML5_FMT.DATETIME_LOCAL),
							message: settings?.clients[modalClientKey]?.message ?? "",
							variant: settings?.clients[modalClientKey]?.variant ?? "",
							queueMessage: settings?.clients[modalClientKey]?.queueMessage ?? "",
						}}
						onSubmit={async (values) => {
							const settingsRef = ref(db, `/settings/clients/${modalClientKey}`);
							set(settingsRef, {
								on: settings?.clients[modalClientKey]?.on ?? false,
								startsAt:
									values.queue === "Scheduled"
										? values.startsAt
										: values.queue === "On"
										? originDate
										: null,
								endsAt: values.queue === "Scheduled" ? values.endsAt : null,
								message: values.message,
								variant: values.variant,
								queueMessage: values.queueMessage,
							})
								.then(() => {
									setModalClientKey(undefined);
								})
								.catch((err) => {
									console.log(err);
									alert("Something went wrong. Check the console for more info.");
								});
						}}
					>
						{({ values, isSubmitting }) => (
							<Form>
								<Modal.Header>
									<Modal.Title>Queue Settings for {modalClientKey}</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									{/* <Form>
						<div key={`inline-radio`} className="mb-3">
							<Form.Check label="Auto" name="queue" type="radio" id={`inline-radio-1`} value="auto" />
							<Form.Check label="On" name="queue" type="radio" id={`inline-$"radio"-2`} value="on" />
							<Form.Check
								label="Between"
								name="queue"
								type="radio"
								id={`inline-$"radio"-3`}
								value="between"
							/>
						</div>
					</Form> */}

									<div role="group" aria-labelledby="my-radio-group">
										<fieldset className="field">
											<label className="d-block">
												<Field type="radio" name="queue" value="Auto" className="m-right" />
												<div
													className="well queue-auto m-0"
													style={{ width: "calc(100% - 23px", display: "inline-block" }}
												>
													<div className="well-content">Auto</div>
												</div>
											</label>
											<label className="d-block">
												<Field type="radio" name="queue" value="On" className="m-right" />
												<div
													className="well queue-forced"
													style={{ width: "calc(100% - 23px", display: "inline-block" }}
												>
													<div className="well-content">On</div>
												</div>
											</label>
											{(values.queue === "Scheduled" ||
												auth.currentUser?.email === "jared@brushfire.com") && (
												<label className="d-block">
													<Field
														type="radio"
														name="queue"
														value="Scheduled"
														className="m-right"
													/>
													<div
														className="well queue-forced"
														style={{ width: "calc(100% - 23px", display: "inline-block" }}
													>
														<div className="well-content">Scheduled</div>
													</div>
												</label>
											)}
										</fieldset>
										{values.queue === "Scheduled" && (
											<div className="row">
												<div className={`col-md-6 field`}>
													<label htmlFor="startsAt">From</label>
													<Field
														type="datetime-local"
														name="startsAt"
														placeholder="Starting Date"
														className="d-block w-100"
													/>
												</div>
												<div className={`col-md-6 field`}>
													<label htmlFor="endsAt">To</label>
													<Field
														type="datetime-local"
														name="endsAt"
														placeholder="Ending Date"
														className="d-block w-100"
													/>
												</div>
											</div>
										)}
										<div className="field">
											<label htmlFor="message">Global Message For {modalClientKey} Users</label>
											<Field
												placeholder="Message for all attendees to see on site"
												as="textarea"
												name="message"
												className="d-block w-100"
											/>
										</div>
										<div className="field">
											<label htmlFor="variant">Global Message Style</label>
											<Field as="select" name="variant" className="d-block w-100">
												<option value="well">Well</option>
												<option value="primary">Primary</option>
												<option value="success">Success</option>
												<option value="info">Info</option>
												<option value="warning">Warning</option>
												<option value="error">Error</option>
											</Field>
										</div>
										<div className="field">
											<label htmlFor="queueMessage">
												Message For {modalClientKey} Users In Queue
											</label>
											<Field
												placeholder="Message for all attendees in queue"
												as="textarea"
												name="queueMessage"
												className="d-block w-100"
											/>
										</div>
									</div>
								</Modal.Body>
								<Modal.Footer>
									<button
										type="submit"
										className={`btn footer-btn ${isSubmitting ? "disabled" : ""}`}
									>
										{isSubmitting ? "Saving..." : "Save"}
									</button>
								</Modal.Footer>
							</Form>
						)}
					</Formik>
				)}
			</Modal>
		</>
	);
};

export default Live;
